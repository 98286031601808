import React from 'react'
import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import FAQ from '../../components/FAQ'
import { Link } from 'gatsby'
import styles from '../../components/layout.module.scss'
import GetStartedOrChat from '../../components/CTA/GetStartedOrChat'

class Page extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <SEO
          title="How to Write Podcast Show Notes"
          description="One of the most efficient (yet, most underrated) things podcasters can do to take their show to a whole new level is creating podcast show notes."
        />

        <article>

          <h1>How to Write Podcast Show Notes</h1>
          <p>
            Many people have started podcasting as a side gig over the past few years and it’s easy to understand why.
            After all, nowadays, there’s a giant audience always looking for new and easy content to consume - and,
            well, what’s easier than listening?
          </p>
          <p>
            But podcasting is about much more than turning on your mic and talking. Creating a high-quality podcast that
            listeners will want to listen to the very end requires time and effort.
          </p>
          <p>
            One of the most efficient (yet, most underrated) things podcasters can do to take their show to a whole new
            level is creating podcast show notes.
          </p>
          <h2>
            <strong>What Are Podcast Show Notes?</strong>
          </h2>
          <img
            src="/images/how-to-write-podcast-show-notes/what-are-podcast-show-notes.jpg"
            alt="How to Write Podcast Show Notes"
          />
          <p>
            Podcast show notes are a written summary of an episode, which includes not only the topics you covered but
            also links to resources where your listeners can learn more relevant information and if you want, links to
            your social media pages.
          </p>
          <p>
            Furthermore, podcast notes are a great opportunity for you to promote your other projects and ask for
            reviews on your show.
          </p>
          <h2>
            <strong>Why Do You Need Podcast Show Notes?</strong>
          </h2>
          <img
            data-src="/images/how-to-write-podcast-show-notes/why-do-you-need-podcast-show-notes.jpg"
            alt="Why Do You Need Podcast Show Notes"
            className="lazyload"
          />
          <p>
            Well-written podcast show notes are useful for a huge number of reasons, both for yourself and the
            listeners:
          </p>
          <ul>
            <li>
              <strong>Convince new (or busy) followers to listen</strong>. Someone who’s not an avid listener of your
              show might need a little convincing before they click play, and the episode’s notes are the ideal chance
              to entice them.
            </li>

            <li>
              <strong>Provide listeners with useful resources</strong>. If your listeners are as interested in your
              show’s topic as you are, they’ll want to know even more about it. Let them trust you to lead them to
              relevant resources.
            </li>

            <li>
              <strong>It gives you a chance to monetize your podcast through affiliate links</strong>. There will always
              be brands in your niche that are trying to promote their products, and in those cases, affiliate marketing
              is a win-win for them and for yourself.
            </li>

            <li>
              <strong>Get new listeners thanks to SEO</strong>.<strong> </strong>As long as you optimize these notes,
              they’ll help you rank better on Google and, as such, they’ll help even more people find your show when
              they research certain expressions.
            </li>

            <li>
              <strong>Promote</strong>. You can (and should) mention your products and services during the episode, but
              including them in your notes is also a must. Remember, it’s much more likely that your listeners will
              click on a link than try to find your products themselves.
            </li>
          </ul>

          <h2>How to Write Great Podcast Show Notes in 5 Steps</h2>
          <p>
            Now that you know why you should create show notes for each of your podcast’s episodes, it’s time to get to
            learn how you can write the best notes possible.
          </p>

          <h3>1. <strong>Take some initial notes before you record the episode</strong></h3>

              <p>
                The first draft of your podcast show notes should be written before you actually record the episode and
                it’ll work not only as a foundation for the final notes, but also as a way for you to stay on track
                during the episode and always know what you’re going to talk about next.
              </p>
              <p>
                Remember that this is just a draft, so it doesn’t need to be perfectly written or crazy detailed. It’s
                more like an outline than anything else.
              </p>

          <h3>2.  <strong>If you want to, add to your draft as you record</strong></h3>
                  <p>
                    We say “if you want to” because not everyone is able to keep an engaging conversation going while
                    also writing down their notes. And when you’re recording your episode, the engaging conversation is
                    a million times more important than the notes!
                  </p>
                  <p>
                    So, if it works for you, take a few extra notes during the recording, but be cautious not to let
                    that affect the quality of the episode.
                  </p>
                  <p>
                    <strong>Pro tip</strong>: If your guest says a great quote that you know you’ll want to include in
                    your notes, just write down the time mark so you can go back to it in the next step.
                  </p>

          <h3>3. <strong>Edit what you’ve written into the podcast show notes</strong></h3>
                  <p>
                    Once your episode is recorded, it’s time to go back to your draft, turn it into the actual podcast
                    show notes and optimize it for SEO.
                  </p>
                  <p>
                    If you don’t remember everything that you spoke about, you can use a tool like{' '}
                    <a href="https://www.videolan.org/index.pt.html">VLC</a> to listen to the episode at a faster speed
                    than usual, without affecting the pitch.
                  </p>
                  <p>
                    This third step will become a lot easier if you have a set format that you simply fill in every
                    time. We have one for you at the end of the article!
                  </p>

          <h3>4. <strong>Add images and links</strong></h3>
                  <p>
                    Once the written content is finished, you can add some visuals to make it more appealing or to help
                    explain complex concepts. After all, no one likes to open a page that’s full of words, words, and
                    more words.
                  </p>
                  <p>
                    This is also when you add a list of resources relevant to the topics you mentioned in the episode,
                    as well as links promoting your guest’s social media pages, products, and projects.
                  </p>

          <h3>5. <strong>End with CTAs</strong></h3>
                  <p>
                    We’ve mentioned several times that your podcast show notes are a great opportunity for promoting
                    your other projects and that’s exactly how you should finish them off. Simply make a list of your
                    current products and services, and then reuse (and update) them as needed.
                  </p>


          <h2>Our Format for Podcast Show Notes</h2>

          <p>
            Your podcast show notes don’t have to be a lengthy, complicated text. There are a few pieces of information
            you should always include and our format lists them all:
          </p>
          <ol>
            <li>Date</li>

            <li>Episode number and name</li>

            <li>A brief and powerful introduction</li>

            <li>A brief biography of the guest(s)</li>

            <li>A list of main topics and insights (with timestamps)</li>

            <li>A list of resources and social media pages (yours and your guest’s)</li>

            <li>CTAs</li>
          </ol>
          <p>Happy podcasting!</p>
        </article>
      </Layout>
    )
  }
}

export default Page
